<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>

    <div class="user-sa-create" v-else>
      <UiBanner fluid :title="isUpdateUser ? userLabel : $t('userSA.create.title')" :breadcrumbs="breadcrumbs">
        <template v-slot:actions v-if="isUpdateUser">
          <v-chip outlined v-if="currentUser.status === true" color="success">
            <v-icon left>
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
            {{ $t('userSA.list.active') }}
          </v-chip>

          <v-chip outlined v-else color="error">
            <v-icon left>
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
            {{ $t('userSA.list.inactive') }}
          </v-chip>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="tw-ml-4" v-bind="attrs" v-on="on">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </template>

            <v-list>
              <v-list-item-group>
                <v-list-item
                  class="tw-text-sm"
                  @click="updateUserStatus(currentUser, false)"
                  v-if="currentUser.status === true"
                >
                  <v-icon left class="tw-mr-4">{{ icons.mdiAccountOff }}</v-icon>
                  {{ $t('userSA.interaction.disabled') }}
                </v-list-item>

                <v-list-item class="tw-text-sm" @click="updateUserStatus(currentUser, true)" v-else>
                  <v-icon left class="tw-mr-4">{{ icons.mdiAccountCheck }}</v-icon>
                  {{ $t('userSA.interaction.reactivate') }}
                </v-list-item>

                <v-list-item class="tw-text-sm" @click="sendUserInvitation(currentUser.email)">
                  <v-icon left class="tw-mr-4">{{ icons.mdiEmail }}</v-icon>
                  {{ $t('userSA.interaction.invitation') }}
                </v-list-item>

                <v-list-item
                  class="tw-text-sm"
                  v-for="(clientUuid, index) in currentUser.clients"
                  :key="`clientUuid ${index}`"
                >
                  <a
                    class="tw-no-underline tw-text-black"
                    :href="`${URLS.BO_V3}${clientUuid}/settings/list/manage/${currentUser.uuid}`"
                    target="_blank"
                  >
                    <v-icon left class="tw-mr-4">{{ icons.mdiAccountEdit }}</v-icon>
                    {{ clientNameByUuid(clientUuid) }}
                  </a>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </UiBanner>
      <UiContainer large with-actions>
        <form class="tw-flex tw-flex-col tw-justify-center" @submit.prevent="onSubmit" novalidate autocomplete="off">
          <div class="user-sa-create__stepper__content__grid">
            <ui-block id="emailLabel" class="required-asterisk" :label="$t('userSA.create.form.email.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="userSAModel.email"
                  id="email"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('userSA.create.form.email.placeholder')"
                  :error-messages="simpleErrors('userSAModel', 'email')"
                />
              </template>
            </ui-block>

            <ui-block id="lastNameLabel" :label="$t('userSA.create.form.lastName.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="userSAModel.lastName"
                  id="lastName"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('userSA.create.form.lastName.placeholder')"
                />
              </template>
            </ui-block>

            <ui-block id="firstNameLabel" :label="$t('userSA.create.form.firstName.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="userSAModel.firstName"
                  id="firstName"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('userSA.create.form.firstName.placeholder')"
                />
              </template>
            </ui-block>

            <ui-block
              id="defaultLocaleIsoCodeLabel"
              class="required-asterisk"
              :label="$t('userSA.create.form.defaultLocaleIsoCode.label')"
            >
              <template v-slot:body>
                <v-autocomplete
                  v-model="userSAModel.defaultLocaleIsoCode"
                  id="defaultLocaleIsoCode"
                  auto-select-first
                  solo
                  flat
                  outlined
                  dense
                  item-value="code"
                  item-text="name"
                  :items="userLocales"
                  :placeholder="$t('userSA.create.form.defaultLocaleIsoCode.placeholder')"
                  :error-messages="simpleErrors('userSAModel', 'defaultLocaleIsoCode')"
                />
              </template>
            </ui-block>

            <ui-block id="roleLabel" class="required-asterisk" :label="$t('userSA.create.form.role.label')">
              <template v-slot:body>
                <v-autocomplete
                  id="role"
                  v-model="userSAModel.role"
                  outlined
                  solo
                  flat
                  dense
                  auto-select-first
                  item-value="name"
                  item-text="value"
                  :items="roles"
                  :placeholder="$t('userSA.create.form.role.placeholder')"
                  :error-messages="simpleErrors('userSAModel', 'role')"
                />
              </template>
            </ui-block>

            <ui-block id="clients" class="required-asterisk" :label="$t('userSA.create.form.clients.label')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="userSAModel.clients"
                  id="clients"
                  solo
                  flat
                  outlined
                  dense
                  multiple
                  item-value="identifier"
                  item-text="name"
                  :items="clients"
                  :placeholder="$t('userSA.create.form.clients.placeholder')"
                  :error-messages="simpleErrors('userSAModel', 'clients')"
                />
              </template>
            </ui-block>
          </div>

          <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
            <v-btn
              :loading="saving"
              :disabled="saving"
              type="submit"
              class="tw-text-white tw-ml-auto tw-px-8"
              color="#449afd"
            >
              {{ $t(`button.${isUpdateUser ? 'save' : 'create'}`) }}
            </v-btn>
          </div>
        </form>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiSortCalendarDescending,
  mdiSortCalendarAscending,
  mdiDotsVertical,
  mdiAccountOff,
  mdiAccountCheck,
  mdiEmail,
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
  mdiAccountEdit,
} from '@mdi/js'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import googleRecaptchaMixin from '@/mixins/google-recaptcha.mixin'
import ErrorsMixin from '@/mixins/errors.mixin'
import exceptionManage from '@/mixins/exceptionManage.mixin'
import { userLocales } from '@/config/userLocales.config'

import { URLS } from '@/config/urls.config'
import { USER_ROLES } from '@/config/permissions.config'

import { cleanString } from '@/utils/utilities.util'

import UiBlock from '@/components/UI/Block.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'UserSAManage',
  components: {
    UiBlock,
    UiBanner,
    UiContainer,
  },
  mixins: [validationMixin, ErrorsMixin, googleRecaptchaMixin, exceptionManage],
  data() {
    return {
      URLS,
      userLocales,
      icons: {
        mdiSortCalendarDescending,
        mdiSortCalendarAscending,
        mdiDotsVertical,
        mdiAccountOff,
        mdiAccountCheck,
        mdiEmail,
        mdiCheckboxMarkedCircleOutline,
        mdiCloseCircleOutline,
        mdiAccountEdit,
      },
      breadcrumbs: [
        {
          text: this.$t('userSA.create.breadcrumbs.list'),
          to: { name: 'UserSAList' },
          exact: true,
        },
        {
          text: this.$t('userSA.create.breadcrumbs.create'),
          to: { name: 'UserSAManage' },
          exact: false,
        },
      ],
      userName: '',
      saving: false,
      userSAModel: {
        email: null,
        firstName: '',
        lastName: '',
        defaultLocaleIsoCode: 'fr-fr',
        role: 'SUPER_ADMIN',
        clients: [],
      },
      currentStep: 1,
    }
  },
  async created() {
    this.setUpdating(true)
    try {
      await Promise.all([
        this.initRecaptcha(),
        this.getAuthentication(),
        this.getClientsCore({ options: { size: 1000 } }),
        this.getRoles(),
        this.isEditRoute && this.getUser({ params: { id: this.$route.params.id } }),
      ])
    } catch (error) {
      this.showNotificationError()
      this.$router.push({ name: 'UserSAList' })
    }

    if (this.isUpdateUser) {
      const { email, firstName, lastName, defaultLocaleIsoCode, role, clients } = this.currentUser
      this.userSAModel = {
        ...this.userSAModel,
        email,
        firstName,
        lastName,
        defaultLocaleIsoCode,
        role,
        clients,
      }
      this.userLabel = lastName.length > 0 ? `${firstName} ${lastName}` : email
    }

    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser,
      roles: state => state.users.roles,

      clients: state => state.client.clients,

      updating: state => state.backoffice.updating,
    }),
    isUpdateUser() {
      return this.currentUser && this.isEditRoute
    },
    isEditRoute() {
      return this.$route.name === 'UserSAEdit'
    },
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      getRoles: 'users/getRoles',
      postUser: 'users/postUser',
      patchUser: 'users/patchUser',
      postUserInvitation: 'users/postUserInvitation',

      getClientsCore: 'client/getClientsCore',

      getAuthentication: 'backoffice/getAuthentication',
      setUpdating: 'backoffice/setUpdating',
    }),
    clientNameByUuid(clientUuid) {
      const uuid = cleanString(clientUuid, '/clients/')
      return this.clients.find(client => client.uuid === uuid).name
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        try {
          if (this.isUpdateUser) {
            await this.patchUser({ params: { id: this.currentUser.uuid }, body: this.userSAModel })
          } else {
            await this.postUser(this.userSAModel)
          }
          this.showNotificationSuccess()
          this.$router.push({ name: 'UserSAList' })
        } catch ({ response }) {
          if (response.status === 422) {
            this.setAlert({
              color: 'error',
              text: this.$t('422.email.duplicate'),
            })
          } else {
            this.showNotificationError()
          }
        }
        this.saving = false
      }
    },
    async sendUserInvitation(email) {
      this.saving = true
      await this.tryManageError(async () => {
        const token = await this.$recaptcha('forgetPassword')
        await this.postUserInvitation({ email, token })
      }, this.$t('notification.success.invitationUser'))
      this.saving = false
    },
    async updateUserStatus(user, status) {
      this.saving = true
      await this.tryManageError(async () => {
        await this.patchUser({ params: { id: user.uuid }, body: { status } })
      }, this.$t('notification.update.user', { name: user.lastName }))
      this.saving = false
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t(`notification.${this.isUpdateUser ? 'update' : 'create'}.user`, {
          name: `${this.userSAModel.lastName} ${this.userSAModel.firstName}`,
        }),
      })
      this.saving = false
    },
  },
  validations() {
    return {
      userSAModel: {
        email: {
          required,
        },
        defaultLocaleIsoCode: {
          required,
        },
        role: {
          required,
        },
        clients: {
          required: requiredIf(() => {
            return USER_ROLES.superAdmin !== this.userSAModel.role
          }),
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.user-sa-create {
  @apply tw-h-full tw-px-0;

  &__stepper {
    @apply tw-my-0;
    background-color: inherit !important;

    &__header {
      box-shadow: none !important;
    }

    &__content {
      @apply tw-mx-0 tw-mt-0 sm:tw-mt-6 sm:tw-mx-4;
      background-color: $white;

      &__grid {
        @apply tw-mx-auto tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-my-4;
      }
    }
  }
}
</style>
